
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$choir-administration-primary: mat.define-palette(mat.$indigo-palette);
$choir-administration-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$choir-administration-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$choir-administration-theme: mat.define-light-theme((
  color: (
    primary: $choir-administration-primary,
    accent: $choir-administration-accent,
    warn: $choir-administration-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($choir-administration-theme);

* {
    position: relative;
    margin: 0;
    padding: 0;

    box-sizing: border-box;

    font-family: 'Roboto', sans-serif;
}

app-root {
    display: block;
    width: 100%;
    height: 100%;
}

body {
    overflow: hidden;
    position: relative;

    height: var(--height); /* calc(100vh - calc(100vh - 100%));*/
    width: 100vw;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
